import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import Link from "next/link";
import { useRouter } from "next/router";
import { Interpolation, Theme, css } from "@emotion/react";
import styled from "@emotion/styled";
import { ToggleButton } from "components/buttons/ToggleButton";
import {
  MIX_CONTENT_TAG_ENUM_KOREAN,
  ORDERED_MIX_CONTENT_TAG_ENUM,
} from "utils/enum";

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const SubMenuList = styled.div`
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

const SubMenu = styled.div<{ focused: boolean }>`
  ${({ theme, focused }) => css`
    ${theme.typography.body3};
    font-weight: ${focused ? "700" : "400"};
    color: ${focused
      ? theme.palette.secondary.main
      : theme.palette.text.disabled};
    padding: 2px 0 2px 48px;
    cursor: pointer;

    &:hover {
      color: ${theme.palette.secondary.main};
    }
  `}
`;

export const NavMenu = ({
  showSubMenu,
  ...props
}: {
  showSubMenu: boolean;
  css?: Interpolation<Theme>;
}) => {
  const router = useRouter();

  /* At the time of initial rendering, open the submenu when the page is index */
  const [isOpen, setIsOpen] = useState(router.pathname === "/" ? true : false);

  /* toggle submenu everytime moving page */
  useEffect(() => {
    if (!showSubMenu) return;
    if (!isOpen && router.pathname === "/") return setIsOpen(true);
    if (isOpen && router.pathname !== "/") setIsOpen(false);
  }, [showSubMenu, isOpen, router.pathname]);

  return (
    <MenuList {...props}>
      <ToggleButton
        name="All"
        active={router.pathname === "/"}
        onClick={() => router.push("/")}
      >
        모든 콘텐츠
      </ToggleButton>
      {showSubMenu && (
        <SubMenuList
          css={css`
            display: flex;
            overflow: hidden;

            ${isOpen
              ? css`
                  max-height: ${ORDERED_MIX_CONTENT_TAG_ENUM.length * 30}px;
                  transition: all 0.2s ease-in;
                `
              : css`
                  max-height: 0;
                  transition: all 0.2s ease-out;
                  padding: 0;
                `}
          `}
        >
          {ORDERED_MIX_CONTENT_TAG_ENUM.map((value) => (
            <Link
              key={value}
              href={`/?tag=${value}`}
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "content_category_click",
                    value,
                  },
                });
              }}
            >
              <SubMenu key={value} focused={router.asPath.endsWith(value)}>
                {MIX_CONTENT_TAG_ENUM_KOREAN[value]}
              </SubMenu>
            </Link>
          ))}
        </SubMenuList>
      )}
      <ToggleButton
        name="Original"
        active={router.pathname === "/original"}
        onClick={() => router.push("/original")}
      >
        오리지널 콘텐츠
      </ToggleButton>
      <ToggleButton
        name="BookmarkFilled"
        active={["/bookmark", "/history"].some((path) =>
          router.pathname.startsWith(path),
        )}
        onClick={() => router.push("/bookmark")}
      >
        북마크
      </ToggleButton>
      <ToggleButton
        name="InfoFilled"
        active={false}
        onClick={() => window.open("https://support.mix.day", "_blank")}
      >
        고객센터
      </ToggleButton>
    </MenuList>
  );
};

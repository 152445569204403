import {
  AnchorHTMLAttributes,
  ClassAttributes,
  ComponentProps,
  HTMLAttributes,
  useState,
} from "react";
import { IconBox } from "../IconBox";
import { Text } from "../Text";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Logo } from "components/Logo";

const FOOTER_INFO = {
  company: "(주)팀민트",
  ceo: "대표 : 한만형",
  businessNumber: "사업자등록번호 : 417-88-02499",
  salesNumber: "통신판매업신고 : 2023-서울강남-05317",
  address: "06092 서울특별시 강남구 선릉로 648, 세림테크빌딩 6층",
  phone: "대표번호 : 070-4327-5067",
  copyright: "© 2022 teammint, inc. All Rights Reserved.",
  copyrightShortVer: "© teammint Corp.",
  instagram: "https://www.instagram.com/mix.day_/",
  linkedin:
    "https://kr.linkedin.com/company/mix-day?trk=public_post_feed-actor-name",
};

const TopBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const LogoInfoListBox = styled.div`
  display: flex;
  gap: 64px;
`;

const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${({ theme }) => theme.typography.body4};
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const SNSLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SNSLink = (
  props: Omit<ComponentProps<typeof Text>, "variant"> &
    ClassAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>,
) => <Text variant="button.medium" tag="a" target="_blank" {...props} />;

export const Footer = (
  props: ClassAttributes<HTMLElement> &
    HTMLAttributes<HTMLElement> & {
      css?: Interpolation<Theme>;
    },
) => {
  const theme = useTheme();

  return (
    <footer
      css={css`
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px var(--page-padding) 48px;
        border-top: 1.5px solid ${theme.palette.text.focusVisible};

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 48px;
          padding-top: 20px;
        }
      `}
      {...props}
    >
      <TopBox>
        <LogoInfoListBox>
          <Logo />
          <InfoList
            css={css`
              @media (max-width: 768px) {
                display: none;
              }
            `}
          >
            <li>{`${FOOTER_INFO.company} | ${FOOTER_INFO.ceo} | ${FOOTER_INFO.businessNumber}`}</li>
            <li>{`${FOOTER_INFO.businessNumber} | ${FOOTER_INFO.salesNumber}`}</li>
            <li>{`${FOOTER_INFO.address} | ${FOOTER_INFO.phone}`}</li>
          </InfoList>
        </LogoInfoListBox>
        <SNSLinks>
          <SNSLink
            css={css`
              padding: 4px 10px;
            `}
            href={FOOTER_INFO.instagram}
          >
            Instagram
          </SNSLink>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="12"
            viewBox="0 0 2 12"
            fill="none"
          >
            <path d="M1 0V12" stroke="#BDBDBD" />
          </svg>
          <SNSLink
            css={css`
              padding: 4px 10px;
            `}
            href={FOOTER_INFO.linkedin}
          >
            Linkedin
          </SNSLink>
        </SNSLinks>
      </TopBox>
      <div
        css={css`
          display: none;

          @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        `}
      >
        <InfoList>
          <li>{`${FOOTER_INFO.company} | ${FOOTER_INFO.ceo}`}</li>
          <li>{FOOTER_INFO.businessNumber}</li>
          <li>{FOOTER_INFO.salesNumber}</li>
          <li>{FOOTER_INFO.address}</li>
          <li>{FOOTER_INFO.phone}</li>
        </InfoList>
        <Text variant="body3">{FOOTER_INFO.copyright}</Text>
      </div>
    </footer>
  );
};

export const SideFooter = (
  props: ClassAttributes<HTMLElement> &
    HTMLAttributes<HTMLElement> & {
      css?: Interpolation<Theme>;
    },
) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <footer
      css={css`
        width: 100%;
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <SNSLinks>
          <SNSLink href={FOOTER_INFO.instagram}>Instagram</SNSLink>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2"
            height="12"
            viewBox="0 0 2 12"
            fill="none"
          >
            <path d="M1 0V12" stroke="#BDBDBD" />
          </svg>
          <SNSLink href={FOOTER_INFO.linkedin}>Linkedin</SNSLink>
        </SNSLinks>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2px;
              align-items: center;
              cursor: pointer;
            `}
            onClick={() => setOpen((prev) => !prev)}
          >
            <Text variant="button.small" color="text.disabled">
              {FOOTER_INFO.company} 사업자 정보
            </Text>
            <IconBox
              css={css`
                transform: rotate(${open ? "180deg" : "0deg"});
              `}
              size="small"
              name="ExpandMore"
              color={theme.palette.grey[400]}
            />
          </div>
          <ul
            css={css`
              display: ${open ? "flex" : "none"};
              flex-direction: column;
            `}
          >
            <Text variant="button.small" color="text.disabled" tag="li">
              {FOOTER_INFO.ceo}
            </Text>
            <Text variant="button.small" color="text.disabled" tag="li">
              {FOOTER_INFO.businessNumber}
            </Text>
            <Text variant="button.small" color="text.disabled" tag="li">
              {FOOTER_INFO.salesNumber}
            </Text>
            <Text variant="button.small" color="text.disabled" tag="li">
              {FOOTER_INFO.address}
            </Text>
            <Text variant="button.small" color="text.disabled" tag="li">
              {FOOTER_INFO.phone}
            </Text>
          </ul>
          <Text variant="caption1" color="text.disabled">
            {FOOTER_INFO.copyrightShortVer}
          </Text>
        </div>
      </div>
    </footer>
  );
};

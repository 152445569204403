import { ComponentProps, useMemo } from "react";
import TagManager from "react-gtm-module";
import Image from "next/image";
import { useRouter } from "next/router";
import { MixModal } from "./MixModal";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import { Text } from "components/Text";
import { IconButton } from "components/buttons/IconButton";
import isWebview from "is-ua-webview";

const SocialLoginButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 300px;

  @media (max-width: 768px) {
    width: 240px;
  }
`;

const SocialLoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 18px 24px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);

  @media (max-width: 768px) {
    padding: 12px 24px;
  }
`;

type SignInModalProps = ComponentProps<typeof MixModal> & {
  close: () => Promise<void>;
};

export const SignInModal = ({ close, ...props }: SignInModalProps) => {
  return (
    <MixModal {...props}>
      <Default close={close} />
    </MixModal>
  );
};

function Default({ close }: Pick<SignInModalProps, "close">) {
  const theme = useTheme();
  const router = useRouter();
  const webview = useMemo(() => isWebview(navigator.userAgent), []);

  const googleSignIn = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: window.location.origin,
    onSuccess: async (response) => {
      const code = response.code;

      try {
        const { data } = await axios.post("/api/oauth/google", {
          code,
          redirect_uri: window.location.origin,
        });

        if (data.isNewUser) {
          router.replace({
            hash: "#signup-form",
          });
        } else {
          await close();
          router.reload(); // clear gtm static params
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          window.alert(error.response?.data);
        }
      }
    },
  });

  const kakaoSignIn = () => {
    const redirect_uri = window.location.origin + "/api/oauth/kakao";
    const app_redirect_uri = window.location.href;

    const params = new URLSearchParams({
      client_id: process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY,
      response_type: "code",
      redirect_uri,
      state: JSON.stringify({
        redirect_uri,
        app_redirect_uri,
      }),
    });

    window.location.href = `https://kauth.kakao.com/oauth/authorize?${params}`;
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        padding: 16px 16px 56px 16px;
        background-color: ${theme.palette.grey["a100"]};
        border-radius: 16px;

        @media (max-width: 768px) {
          padding-bottom: 40px;
          width: 342px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: 8px;
        `}
      >
        <IconButton
          name="Close"
          variant="background"
          color="grey"
          size="large"
          onClick={close}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 40px;
        `}
      >
        <header
          css={css`
            display: flex;
            flex-direction: column;
            gap: 8px;
            text-align: center;
            word-break: keep-all;
          `}
        >
          <Text
            css={css`
              display: block;

              @media (max-width: 768px) {
                display: none;
              }
            `}
            variant="h2"
          >
            믹스에 오신것을 환영합니다!
          </Text>
          <Text
            css={css`
              display: none;

              @media (max-width: 768px) {
                display: block;
                white-space: pre-wrap;
              }
            `}
            variant="h2"
          >{`믹스에 오신것을\n환영합니다!`}</Text>
          <Text
            css={css`
              @media (max-width: 768px) {
                ${theme.typography.body3};
              }
            `}
            variant="body1"
            color="text.disabled"
          >
            믹스가 엄선한 마케팅 콘텐츠를 경험해보세요.
          </Text>
        </header>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
          `}
        >
          <SocialLoginButtons>
            {!webview && (
              <SocialLoginButton
                css={css`
                  background-color: ${theme.palette.common.white.main};
                `}
                type="button"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "login",
                      method: "google",
                      prev_webview_access:
                        router.query.prev_webview_access === "true",
                    },
                  });

                  googleSignIn();
                }}
              >
                <div
                  css={css`
                    position: relative;
                    width: 22px;
                    height: 22px;
                  `}
                >
                  <Image alt="google" src="/img/google.png" fill />
                </div>
                <Text variant="subtitle2" color="text.primary">
                  구글 로그인
                </Text>
              </SocialLoginButton>
            )}
            <SocialLoginButton
              css={css`
                background-color: #fddc3f;
              `}
              type="button"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: "login",
                    method: "kakao",
                    prev_webview_access:
                      router.query.prev_webview_access === "true",
                  },
                });

                kakaoSignIn();
              }}
            >
              <div
                css={css`
                  position: relative;
                  width: 22px;
                  height: 20.31px;
                `}
              >
                <Image alt="kakao" src="/img/kakao.png" fill />
              </div>
              <Text
                css={css`
                  color: #3c1e1e;
                `}
                variant="subtitle2"
              >
                카카오 로그인
              </Text>
            </SocialLoginButton>
          </SocialLoginButtons>
          <Text
            css={css`
              width: 350px;
              text-align: center;
              word-break: keep-all;
              white-space: pre-wrap;

              a {
                text-decoration: underline;
              }

              @media (max-width: 768px) {
                width: 240px;
              }
            `}
            variant="caption2"
            color="text.placeholder"
            tag="p"
          >
            {"로그인은 "}
            <a
              href="https://support.mix.day/e8ceb0d6-cba8-496f-873f-19e657abe168"
              target="_blank"
              rel="noreferrer"
            >
              개인정보 보호정책
            </a>
            {" 및 "}
            <a
              href="https://support.mix.day/7892acb6-7072-477d-abc1-224fe83fb855"
              target="_blank"
              rel="noreferrer"
            >
              서비스 약관
            </a>
            에 동의하는 것을 의미하며 서비스 이용을 위해 이메일과 이름, 프로필
            이미지를 수집합니다.
          </Text>
        </div>
      </div>
    </div>
  );
}

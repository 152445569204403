import { css } from "@emotion/react";

export const theme = {
  breakPoint: {
    web: 1920,
    tablet: 1200,
    app: 375,
  },
  collection: {
    color: "rgba(255, 255, 255, 1)",
  },
  palette: {
    text: {
      primary: "rgba(18, 18, 18, 1)",
      secondary: "rgba(119, 119, 119, 1)",
      disabled: "rgba(153, 153, 153, 1)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      focus: "rgba(0, 0, 0, 0.12)",
      focusVisible: "rgba(0, 0, 0, 0.1)",
      placeholder: "rgba(170, 170, 170, 1)",
      primaryWhite: "rgba(255, 255, 255, 1)",
      secondaryWhite: "rgba(204, 204, 204, 1)",
    },
    primary: {
      main: "rgba(99, 143, 255, 1)",
      dark: "rgba(75, 119, 229, 1)",
      hover: "rgba(99, 143, 255, 0.1)",
      selected: "rgba(99, 143, 255, 0.14)",
      focus: "rgba(99, 143, 255, 0.18)",
      focusVisible: "rgba(99, 143, 255, 0.22)",
      outlinedBorder: "rgba(99, 143, 255, 0.7)",
    },
    secondary: {
      main: "rgba(51, 51, 51, 1)",
      dark: "rgba(33, 33, 33, 1)",
      contrast: "rgba(255, 255, 255, 1)",
      hover: "rgba(66, 66, 66, 0.08)",
      selected: "rgba(66, 66, 66, 0.12)",
      focus: "rgba(66, 66, 66, 0.15)",
      focusVisible: "rgba(66, 66, 66, 0.3)",
      outlinedBorder: "rgba(66, 66, 66, 0.15)",
    },
    subcolor: {
      blue: "rgba(55, 111, 166, 1)",
      orange: "rgba(255, 147, 21, 1)",
      backGround: "rgba(250, 250, 250, 1)",
      backGroundBalck: "rgba(0, 0, 0, 0.5)",
    },
    grey: {
      50: "rgba(252, 252, 252, 1)",
      100: "rgba(245, 245, 245, 1)",
      200: "rgba(238, 238, 238, 1)",
      300: "rgba(219, 219, 219, 1)",
      400: "rgba(189, 189, 189, 1)",
      500: "rgba(158, 158, 158, 1)",
      600: "rgba(117, 117, 117, 1)",
      700: "rgba(97, 97, 97, 1)",
      800: "rgba(66, 66, 66, 1)",
      900: "rgba(45, 45, 45, 1)",
      a100: "rgba(248, 248, 248, 1)",
    },
    action: {
      active: "rgba(119, 119, 119, 1)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabledBackground: "rgba(0, 0, 0, 0.05)",
      focus: "rgba(0, 0, 0, 0.12)",
      disabled: "rgba(0, 0, 0, 0.15)",
    },
    error: {
      main: "rgba(216, 67, 21, 1)",
      dark: "rgba(191, 54, 12, 1)",
      hover: "rgba(216, 67, 21, 0.04)",
      selected: "rgba(216, 67, 21, 0.08)",
      focusVisible: "rgba(216, 67, 21, 0.3)",
      outlinedBorder: "rgba(216, 67, 21, 0.2)",
      contrast: "rgba(255, 255, 255, 1)",
    },
    warning: {
      main: "rgba(255, 152, 0, 1)",
      dark: "rgba(251, 140, 0, 1)",
      hover: "rgba(255, 152, 0, 0.04)",
      selected: "rgba(255, 152, 0, 0.08)",
      contrast: "rgba(255, 255, 255, 1)",
      focusVisible: "rgba(255, 152, 0, 0.3)",
      outlinedBorder: "rgba(215, 128, 0, 0.25)",
    },
    info: {
      main: "rgba(2, 136, 209, 1)",
      dark: "rgba(0, 114, 177, 1)",
      hover: "rgba(2, 136, 209, 0.06)",
      selected: "rgba(2, 136, 209, 0.12)",
      focusVisible: "rgba(2, 136, 209, 0.3)",
      outlinedBorder: "rgba(2, 136, 209, 0.25)",
      contrast: "rgba(255, 255, 255, 1)",
    },
    success: {
      main: "rgba(0, 193, 147, 1)",
      dark: "rgba(0, 167, 137, 1)",
      contrast: "rgba(255, 255, 255, 1)",
      hover: "rgba(0, 193, 147, 0.08)",
      selected: "rgba(0, 193, 147, 0.12)",
      focusVisible: "rgba(0, 193, 147, 0.3)",
      outlinedBorder: "rgba(0, 193, 147, 0.25)",
    },
    common: {
      white: {
        main: "rgba(255, 255, 255, 1)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        focus: "rgba(255, 255, 255, 0.12)",
        focusVisible: "rgba(255, 255, 255, 0.3)",
        outlinedBorder: "rgba(255, 255, 255, 0.5)",
      },
      black: {
        main: "rgba(0, 0, 0, 1)",
        hover: "rgba(0, 0, 0, 0.04)",
        selected: "rgba(0, 0, 0, 0.08)",
        focus: "rgba(0, 0, 0, 0.12)",
        focusVisible: "rgba(0, 0, 0, 0.3)",
        outlinedBorder: "rgba(0, 0, 0, 0.5)",
      },
    },
    components: {
      divider: {
        line: "rgba(0, 0, 0, 0.12)",
      },
      tooltip: {
        fill: "rgba(18, 18, 18, 0.9)",
      },
    },
    alert: {
      error: {
        color: "rgba(95, 33, 32, 1)",
        background: "rgba(253, 237, 237, 1)",
      },
      warning: {
        color: "rgba(102, 60, 0, 1)",
        background: "rgba(255, 244, 229, 1)",
      },
      info: {
        color: "rgba(1, 67, 97, 1)",
        background: "rgba(229, 246, 253, 1)",
      },
      success: {
        color: "rgba(30, 70, 32, 1)",
        background: "rgba(237, 247, 237, 1)",
      },
    },
    scirm: "rgba(0, 0, 0, 0.5)",
    line: "rgba(238, 238, 238, 1)",
  },
  shape: {
    radius: {
      none: 0,
      medium: 8,
    },
  },
  typography: {
    h1: css`
      font-family: "Pretendard";
      font-size: 40px;
      font-weight: 700;
      line-height: 135.0000023841858%;
    `,
    h2: css`
      font-family: "Pretendard";
      font-size: 28px;
      font-weight: 700;
      line-height: 135.0000023841858%;
    `,
    h3: css`
      font-family: "Pretendard";
      font-size: 24px;
      font-weight: 700;
      line-height: 135.0000023841858%;
    `,
    h4: css`
      font-family: "Pretendard";
      font-size: 20px;
      font-weight: 700;
      line-height: 135.0000023841858%;
    `,
    subtitle1: css`
      font-family: "Pretendard";
      font-size: 18px;
      font-weight: 700;
      line-height: 139.9999976158142%;
    `,
    subtitle2: css`
      font-family: "Pretendard";
      font-size: 16px;
      font-weight: 600;
      line-height: 139.9999976158142%;
    `,
    body1: css`
      font-family: "Pretendard";
      font-size: 16px;
      font-weight: 400;
      line-height: 139.9999976158142%;
    `,
    body2: css`
      font-family: "Pretendard";
      font-size: 14px;
      font-weight: 600;
      line-height: 139.9999976158142%;
    `,
    body3: css`
      font-family: "Pretendard";
      font-size: 14px;
      font-weight: 400;
      line-height: 139.9999976158142%;
    `,
    body4: css`
      font-family: "Pretendard";
      font-size: 13px;
      font-weight: 400;
      line-height: 139.9999976158142%;
    `,
    caption1: css`
      font-family: "Pretendard";
      font-size: 12px;
      font-weight: 600;
      line-height: 139.9999976158142%;
    `,
    caption2: css`
      font-family: "Pretendard";
      font-size: 12px;
      font-weight: 400;
      line-height: 139.9999976158142%;
    `,
    button: {
      large: css`
        font-family: "Pretendard";
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      `,
      medium: css`
        font-family: "Pretendard";
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      `,
      small: css`
        font-family: "Pretendard";
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
      `,
    },
    input: {
      value: css`
        font-family: "Pretendard";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      `,
    },
  },
  effects: {
    mainShadow: css`
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
    `,
    maindarkShadow: css`
      box-shadow: 0px 12px 32px rgba(14, 119, 93, 0.12);
    `,
    centerBlur: css`
      box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
    `,
  },
};

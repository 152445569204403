import { HTMLProps, ReactNode, Suspense } from "react";
import { css } from "@emotion/react";
import * as icons from "lib/icons";

type IconProps = Omit<HTMLProps<HTMLElement>, "size"> & {
  name: IconName;
  fallback: ReactNode;
};

const iconCache = new Map();

export const Icon = ({ name, fallback, ...props }: IconProps) => {
  iconCache.set(name, icons[name]);

  const Component = iconCache.get(name)!;

  return (
    <Suspense fallback={fallback}>
      <Component
        css={css`
          * {
            fill: currentColor;
          }
        `}
        {...props}
      />
    </Suspense>
  );
};

import { ButtonHTMLAttributes } from "react";
import { IconBox } from "../IconBox";
import { css, useTheme } from "@emotion/react";

type ButtonProps = {
  variant?: "filled" | "outlined" | "text" | "background";
  color?: "primary" | "secondary" | "grey";
  size?: "xl" | "large" | "medium" | "small";
  startIcon?: IconName;
  endIcon?: IconName;
  interaction?: "hover";
  loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  variant = "filled",
  color = "primary",
  size = "medium",
  startIcon,
  endIcon,
  interaction,
  loading = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const theme = useTheme();

  const { padding, gap, typography } = {
    xl: {
      padding: "12px 17px",
      gap: 2,
      typography: theme.typography.button.large,
    },
    large: {
      padding: "8px 13px",
      gap: 2,
      typography: theme.typography.button.large,
    },
    medium: {
      padding: "4px 11px",
      gap: 2,
      typography: theme.typography.button.medium,
    },
    small: {
      padding: "2px 9px",
      gap: 2,
      typography: theme.typography.button.small,
    },
  }[size];

  const {
    enabled,
    hovered,
    disabled: disabledStyle,
  } = {
    filled:
      color === "grey"
        ? {
            enabled: {
              backgroundColor: theme.palette.grey[200],
              borderColor: "transparent",
              color: theme.palette.text.secondary,
            },
            hovered: {
              backgroundColor: theme.palette.grey[300],
              borderColor: "transparent",
              color: theme.palette.text.secondary,
            },
            disabled: {
              backgroundColor: theme.palette.grey[200],
              borderColor: "transparent",
              color: theme.palette.text.placeholder,
            },
          }
        : {
            enabled: {
              backgroundColor: theme.palette[color].main,
              borderColor: "transparent",
              color: theme.palette.common.white.main,
            },
            hovered: {
              backgroundColor: theme.palette[color].dark,
              borderColor: "transparent",
              color: theme.palette.common.white.main,
            },
            disabled: {
              borderColor: "transparent",
              backgroundColor: theme.palette.grey[200],
              color: theme.palette.text.placeholder,
            },
          },
    outlined:
      color === "grey"
        ? {
            enabled: {
              backgroundColor: "transparent",
              borderColor: theme.palette.grey[400],
              color: theme.palette.text.disabled,
            },
            hovered: {
              backgroundColor: "transparent",
              borderColor: theme.palette.grey[500],
              color: theme.palette.text.secondary,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: theme.palette.grey[400],
              color: theme.palette.text.placeholder,
            },
          }
        : {
            enabled: {
              backgroundColor: "transparent",
              borderColor: theme.palette[color].main,
              color: theme.palette[color].main,
            },
            hovered: {
              backgroundColor: "transparent",
              borderColor: theme.palette[color].dark,
              color: theme.palette[color].dark,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: theme.palette.grey[400],
              color: theme.palette.text.placeholder,
            },
          },
    text:
      color === "grey"
        ? {
            enabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.disabled,
            },
            hovered: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.disabled,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.placeholder,
            },
          }
        : {
            enabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette[color].main,
            },
            hovered: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette[color].main,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.placeholder,
            },
          },
    background:
      color === "grey"
        ? {
            enabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.secondary,
            },
            hovered: {
              backgroundColor: theme.palette.grey["a100"],
              borderColor: "transparent",
              color: theme.palette.text.secondary,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.placeholder,
            },
          }
        : {
            enabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette[color].main,
            },
            hovered: {
              backgroundColor: theme.palette[color].hover,
              borderColor: "transparent",
              color: theme.palette[color].main,
            },
            disabled: {
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: theme.palette.text.placeholder,
            },
          },
  }[variant];

  return (
    <button
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${gap}px;
        padding: ${padding};
        border-radius: ${theme.shape.radius.medium}px;
        background-color: ${enabled.backgroundColor};
        box-shadow: inset 0 0 0 1px ${enabled.borderColor};
        color: ${enabled.color};
        ${typography};
        cursor: pointer;

        .icon {
          color: ${enabled.color};
        }

        &:not(:disabled) {
          &:hover,
          &[data-interaction="hover"] {
            background-color: ${hovered.backgroundColor};
            box-shadow: inset 0 0 0 1px ${hovered.borderColor};
            color: ${hovered.color};

            .icon {
              color: ${hovered.color};
            }
          }
        }

        &:disabled {
          background-color: ${disabledStyle.backgroundColor};
          box-shadow: inset 0 0 0 1px ${disabledStyle.borderColor};
          color: ${disabledStyle.color};
          cursor: not-allowed;

          .icon {
            color: ${disabledStyle.color};
          }
        }
      `}
      type="button"
      disabled={disabled || loading}
      data-interaction={interaction}
      {...props}
    >
      {startIcon && (
        <IconBox
          css={css`
            margin-left: ${-gap / 2}px;
          `}
          className="icon"
          name={startIcon}
          size={size}
        />
      )}
      {children}
      {endIcon && (
        <IconBox
          css={css`
            margin-right: ${-gap / 2}px;
          `}
          className="icon"
          name={endIcon}
          size={size}
        />
      )}
    </button>
  );
};

import { ComponentProps } from "react";
import { Icon } from "./Icon";
import { css } from "@emotion/react";

type IconBoxProps = Omit<ComponentProps<typeof Icon>, "fallback"> & {
  name: IconName;
  size?: "xl" | "large" | "medium" | "small";
  color?: string;
};

export const IconBox = ({ size = "large", color, ...props }: IconBoxProps) => {
  const pixel = {
    xl: 32,
    large: 28,
    medium: 24,
    small: 20,
  }[size];

  return (
    <Icon
      css={css`
        width: ${pixel}px;
        height: ${pixel}px;
        color: ${color};
      `}
      fallback={
        <div
          css={css`
            width: ${pixel}px;
            height: ${pixel}px;
            color: ${color};
          `}
        />
      }
      {...props}
    />
  );
};

import { ComponentProps } from "react";
import TagManager from "react-gtm-module";
import { useRouter } from "next/router";
import { TextField } from "./TextField";

export function SearchTextField({
  value,
  ...props
}: Omit<ComponentProps<typeof TextField>, "variant" | "startItem">) {
  const router = useRouter();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (value !== "") {
          TagManager.dataLayer({
            dataLayer: {
              event: "content_search",
              text: value as string,
            },
          });

          router.push({
            pathname: "/search",
            query: {
              text: value,
            },
          });
        }
      }}
    >
      <TextField
        size="medium"
        variant="filled"
        placeholder="콘텐츠 검색"
        value={value}
        startItem={<TextField.StartIcon name="SearchFilled" />}
        {...props}
      />
    </form>
  );
}

import { ComponentProps, useCallback, useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import Image from "next/image";
import { useRouter } from "next/router";
import { MixModal } from "./MixModal";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Text } from "components/Text";
import { Button } from "components/buttons/Button";
import { useUpdateMeMutation } from "graphql/generated";
import { useAuth } from "hooks/useAuth";
import {
  ORDERED_CAREERS,
  ORDERED_POSITION,
  POSITION_KOREAN,
  Position,
} from "utils/enum";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardList = styled.div`
  --card-max-width: 150px;

  display: grid;
  grid-gap: 8px;
  grid-template-columns:
    minmax(0, var(--card-max-width)) minmax(0, var(--card-max-width))
    minmax(0, var(--card-max-width));
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

const Card = styled.button<{ selected: boolean }>`
  max-width: var(--card-max-width);
  width: 100%;
  height: 54px;
  border-radius: 12px;
  word-break: keep-all;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  ${({ theme }) => theme.typography.subtitle2};

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.palette.subcolor.blue};
          color: white;
          font-weight: 700;
        `
      : css`
          background-color: ${theme.palette.grey[100]};
          color: ${theme.palette.text.secondary};
          font-weight: 400;
        `};
`;

enum Stage {
  position,
  career,
  subscribeEmail,
}

type SelectType<T = any> = (data: { [key in Stage]: T }) => T;

export const CareerModal = ({
  me,
  close,
  onRequestClose,
  ...props
}: ComponentProps<typeof MixModal> & {
  me: NonNullable<SessionQuery["session"]["me"]>;
  close: () => Promise<void>;
}) => {
  const theme = useTheme();
  const router = useRouter();
  const { refetch } = useAuth();

  const [stage, setStage] = useState<Stage>(Stage.position);
  const [position, setPosition] = useState<Position | null>(null);
  const [career, setCareer] = useState<string | null>(null);
  const [subscribeEmail, setSubscribeEmail] = useState<boolean>(
    me?.subscribeEmail ?? false,
  );

  const select = useCallback<SelectType>((data) => data[stage], [stage]);

  const [updateMe] = useUpdateMeMutation({
    onCompleted: async (response) => {
      if (response.update_mix_user_by_pk) {
        const { career, position } = response.update_mix_user_by_pk;
        const prev_webview_access = router.query.prev_webview_access === "true";

        window.fbq("track", "CompleteRegistration", {
          method: me?.method,
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "sign_up",
            method: me?.method,
            subscribeEmail,
            prev_webview_access,
          },
        });

        TagManager.dataLayer({
          dataLayer: {
            career,
            position,
          },
        });
      }

      await close();
      router.reload(); // clear gtm static params
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <MixModal {...props}>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 600px;
          padding: 56px 16px;
          background-color: ${theme.palette.common.white.main};
          border-radius: 24px;
          margin: 0 24px;

          & * {
            text-align: center;
          }

          @media (max-width: 768px) {
            padding: 48px 32px 32px;
            border-radius: 20px;
          }

          ${stage === Stage.subscribeEmail &&
          css`
            max-width: 375px;
            padding: 40px 16px;
          `}
        `}
      >
        <Text
          css={css`
            white-space: pre-wrap;
            margin-bottom: 40px;

            @media (max-width: 768px) {
              ${theme.typography.h3};
              margin-bottom: 32px;
            }

            ${stage === Stage.subscribeEmail &&
            css`
              margin-bottom: 8px;
            `}
          `}
          variant="h2"
          tag="header"
        >
          {select({
            [Stage.position]: "나의 직무를 선택해주세요.",
            [Stage.career]: "나의 연차를 선택해주세요.",
            [Stage.subscribeEmail]: (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `}
              >
                <div
                  css={css`
                    padding: 13px 0;
                    margin-bottom: 8px;
                  `}
                >
                  <div
                    css={css`
                      position: relative;
                      width: 74px;
                      height: 74px;
                    `}
                  >
                    <Image
                      alt="newsletter"
                      src="/img/newsletter.png"
                      fill={true}
                    />
                  </div>
                </div>
                {`믹스 뉴스레터를\n받아 보시겠어요?`}
              </div>
            ),
          })}
        </Text>
        <Content>
          {select({
            [Stage.position]: (
              <CardList>
                {ORDERED_POSITION.map(($position) => (
                  <Card
                    key={$position}
                    selected={position === $position}
                    onClick={() => {
                      setPosition($position);
                      setStage(Stage.career);
                    }}
                  >
                    {POSITION_KOREAN[$position]}
                  </Card>
                ))}
              </CardList>
            ),
            [Stage.career]: (
              <CardList>
                {ORDERED_CAREERS.map(($career) => (
                  <Card
                    key={$career}
                    selected={career === $career}
                    onClick={() => {
                      setCareer($career);
                      setStage(Stage.subscribeEmail);
                    }}
                  >
                    {$career}
                  </Card>
                ))}
              </CardList>
            ),
            [Stage.subscribeEmail]: (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Text
                  css={css`
                    color: ${theme.palette.text.disabled};
                    white-space: pre-wrap;
                    margin-bottom: 40px;
                  `}
                  variant="body3"
                >
                  {`오리지널 콘텐츠와 인기 콘텐츠를\n메일로 보내드려요.`}
                </Text>
                <div
                  css={css`
                    display: flex;
                    gap: 8px;

                    button {
                      width: 140px;
                    }
                  `}
                >
                  <Button
                    size="xl"
                    variant="filled"
                    color="grey"
                    onClick={() => {
                      setSubscribeEmail(false);

                      updateMe({
                        variables: {
                          id: me.id,
                          _set: {
                            position,
                            career,
                            subscribeEmail: false,
                          },
                        },
                      });
                    }}
                  >
                    다음에 할게요
                  </Button>
                  <Button
                    size="xl"
                    onClick={() => {
                      setSubscribeEmail(true);

                      updateMe({
                        variables: {
                          id: me.id,
                          _set: {
                            position,
                            career,
                            subscribeEmail: true,
                          },
                        },
                      });
                    }}
                  >
                    받아볼래요
                  </Button>
                </div>
              </div>
            ),
          })}
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 32px;
              margin-top: 24px;

              @media (max-width: 768px) {
                margin-top: 16px;
              }

              ${stage === Stage.subscribeEmail &&
              css`
                height: 100%;
                margin-top: 0;

                @media (max-width: 768px) {
                  margin-top: 0;
                }
              `}
            `}
          >
            <div>
              {select({
                [Stage.position]: <></>,
                [Stage.career]: (
                  <Button
                    variant="background"
                    color="grey"
                    size="medium"
                    startIcon="ChevronLeft"
                    onClick={() => setStage(Stage.position)}
                  >
                    이전
                  </Button>
                ),
                [Stage.subscribeEmail]: <></>,
              })}
            </div>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                color: ${theme.palette.text.placeholder};
                ${theme.typography.body3};
              `}
            >
              {select({
                [Stage.position]: "1/2",
                [Stage.career]: "2/2",
                [Stage.subscribeEmail]: "",
              })}
            </div>
          </div>
        </Content>
      </div>
    </MixModal>
  );
};
